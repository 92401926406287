import { SearchResultsPageLabels, SearchResultCardLabels, FilterLabels, GetDirectionsLabels } from '@/types'

export function resultsPagLabelsFactory(page): SearchResultsPageLabels {
  const map = page.map
  const resultCard = page.resultCard
  const filterLabels = page.filterLabels
  const getDirectionsLabels = page.getDirectionsLabels
  const plusSideBarLabels = page && page.plusSideBar
  return {
    accessibilityLabel: page.accessibility as string,
    brandDropdownOptions: page.brandDropdownOptions as string[],
    noResultsFromFetchMessage: page.noResultsFromFetchMessage as string,
    noResultsFromFiltersMessage: page.noResultsFromFiltersMessage as string,
    disclaimer: page.disclaimer as string,
    nonTieredDisclaimer: page.nonTieredDisclaimer as string,
    plusProviderFlashMessage: page.plusProviderFlashMessage as string,
    searchAgainLabel: page.searchAgainLabel as string,
    resultsForLabel: page.resultsForLabel as string,
    filteredResultsForLabel: page.filteredResultsForLabel as string,
    nearYouLabel: page.nearYouLabel as string,
    networkLabel: page.networkLabel as string,
    filterButtonLabel: page.filterButtonLabel as string ,
    printButtonLabel: page.printButtonLabel as string ,
    emailButtonLabel: page.emailButtonLabel as string ,
    emailFormSubmitLabel: page.emailFormSubmitLabel as string ,
    emailFormInputLabel: page.emailFormInputLabel as string ,
    map: {
      collapseLabel: map.collapseLabel as string,
      expandLabel: map.expandLabel as string
    },
    medicaidInfoBox: page.medicaidInfoBox as string,
    showMedicaidInfo: page.showMedicaidInfo as boolean,
    hideBrandFilter: page.hideBrandFilter as boolean,
    filterLabels: filterLabels as FilterLabels,
    resultCard: resultCard as SearchResultCardLabels,
    getDirectionsLabels: getDirectionsLabels as GetDirectionsLabels,
    offerButtonLabel: page.offerButtonLabel as string,
    offerInfoPrivate: page.offerInfoPrivate as string,
    offerInfoPublic: page.offerInfoPublic as string,
    offerPopupIndependent: page.offerPopupIndependent as string,
    hideOfferInfoboxPrivate: page.hideOfferInfoboxPrivate as boolean,
    hideOfferInfoboxPublic: page.hideOfferInfoboxPublic as boolean,
    plusSideBar: {
      scheduleAppointmentLabel: plusSideBarLabels && plusSideBarLabels.scheduleAppointmentLabel as string
    }
  }
}
